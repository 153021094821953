var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"centered":"","mask-closable":false,"ok-button-props":{ props: { loading: _vm.submitting } },"title":"编辑逝者信息","width":"1300px"},on:{"ok":_vm.handleSubmit},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('a-form',{staticClass:"custom-compact-form",attrs:{"form":_vm.form,"label-col":{ span: 7 },"wrapper-col":{ span: 13 }},on:{"submit":_vm.handleSubmit}},[_c('a-row',[_c('a-col',{attrs:{"span":"12"}},[_c('a-form-item',{attrs:{"label":"逝者姓名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['name', {
                initialValue: _vm.deathInfo.name,
                normalize: this.$lodash.trim,
                rules: [
                  { required: true, message: '请输入逝者姓名' },
                  { max: 30, message: '最多30个字符' } ]
              }]),expression:"['name', {\n                initialValue: deathInfo.name,\n                normalize: this.$lodash.trim,\n                rules: [\n                  { required: true, message: '请输入逝者姓名' },\n                  { max: 30, message: '最多30个字符' },\n                ]\n              }]"}],attrs:{"autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":"身份证号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['identity_card_no', {
                initialValue: _vm.deathInfo.identity_card_no,
                normalize: this.$lodash.trim,
                rules: [
                  { required: true, message: '请输入身份证号' },
                  { pattern: /^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/, message: '身份证号格式不正确' } ]
              }]),expression:"['identity_card_no', {\n                initialValue: deathInfo.identity_card_no,\n                normalize: this.$lodash.trim,\n                rules: [\n                  { required: true, message: '请输入身份证号' },\n                  { pattern: /^[1-9]\\d{5}[1-9]\\d{3}((0\\d)|(1[0-2]))(([0|1|2]\\d)|3[0-1])\\d{3}([0-9]|X)$/, message: '身份证号格式不正确' },\n                ]\n              }]"}],attrs:{"autocomplete":"off"},on:{"change":_vm.calAge}})],1),_c('a-form-item',{attrs:{"label":"性别"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['sex',{
                initialValue: _vm.deathInfo.sex,
                rules: [
                  { required: true, message: '请选择逝者性别' } ]}]),expression:"['sex',{\n                initialValue: deathInfo.sex,\n                rules: [\n                  { required: true, message: '请选择逝者性别' },\n                ]}]"}]},_vm._l((_vm.sexOptions),function(option,index){return _c('a-radio',{key:index,attrs:{"value":option.value}},[_vm._v(_vm._s(option.text))])}),1)],1),_c('a-form-item',{attrs:{"label":"年龄"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['age', {
                initialValue: _vm.deathInfo.age,
                normalize: this.$lodash.trim,
                rules: [
                  { required: true, message: '请输入逝者年龄' },
                  { pattern: /^([1-9][0-9]*)$/, message: '请输入逝者年龄' } ]
              }]),expression:"['age', {\n                initialValue: deathInfo.age,\n                normalize: this.$lodash.trim,\n                rules: [\n                  { required: true, message: '请输入逝者年龄' },\n                  { pattern: /^([1-9][0-9]*)$/, message: '请输入逝者年龄' },\n                ]\n              }]"}],attrs:{"min":1,"max":999}})],1),_c('a-form-item',{attrs:{"label":"宗教信仰"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['religion',{
                initialValue: _vm.deathInfo.religion,
                rules: [ { required: true, message: '请选择宗教信仰' } ]
              }]),expression:"['religion',{\n                initialValue: deathInfo.religion,\n                rules: [ { required: true, message: '请选择宗教信仰' },]\n              }]"}],attrs:{"placeholder":"请选择宗教信仰","allow-clear":"","mode":"combobox"}},_vm._l((_vm.religionOptions),function(name){return _c('a-select-option',{key:name,attrs:{"value":name}},[_vm._v(" "+_vm._s(name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"出生日期"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'birth_day',{
                  initialValue: _vm.deathInfo.birth_day,
                }
              ]),expression:"[\n                'birth_day',{\n                  initialValue: deathInfo.birth_day,\n                }\n              ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"请选择出生日期"}})],1),_c('a-form-item',{attrs:{"label":"是否为党员"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['party_member',{
                initialValue: _vm.deathInfo.party_member,
              }]),expression:"['party_member',{\n                initialValue: deathInfo.party_member,\n              }]"}]},[_c('a-radio',{attrs:{"value":"yes"}},[_vm._v("是")]),_c('a-radio',{attrs:{"value":"no"}},[_vm._v("否")])],1)],1),_c('a-form-item',{attrs:{"label":"逝者职业"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['occupation', {
                initialValue: _vm.deathInfo.occupation,
                normalize: this.$lodash.trim,
                rules: [
                  { max: 30, message: '最多30个字符' } ]
              }]),expression:"['occupation', {\n                initialValue: deathInfo.occupation,\n                normalize: this.$lodash.trim,\n                rules: [\n                  { max: 30, message: '最多30个字符' },\n                ]\n              }]"}],attrs:{"autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":"家庭住址"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['address', {
                initialValue: _vm.deathInfo.address,
                normalize: this.$lodash.trim,
                rules: [
                  { max: 100, message: '最多100个字符' } ]
              }]),expression:"['address', {\n                initialValue: deathInfo.address,\n                normalize: this.$lodash.trim,\n                rules: [\n                  { max: 100, message: '最多100个字符' },\n                ]\n              }]"}],attrs:{"autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":"是否行三跪九叩首大礼"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['san_gui_jiu_kou',{
                initialValue: _vm.deathInfo.san_gui_jiu_kou,
              }]),expression:"['san_gui_jiu_kou',{\n                initialValue: deathInfo.san_gui_jiu_kou,\n              }]"}]},[_c('a-radio',{attrs:{"value":"agree"}},[_vm._v("是")]),_c('a-radio',{attrs:{"value":"disagree"}},[_vm._v("否")])],1)],1),_c('a-form-item',{attrs:{"label":"家属取抽身布"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['pull_out_cloth',{
                initialValue: _vm.deathInfo.pull_out_cloth,
              }]),expression:"['pull_out_cloth',{\n                initialValue: deathInfo.pull_out_cloth,\n              }]"}]},[_c('a-radio',{attrs:{"value":"agree"}},[_vm._v("是")]),_c('a-radio',{attrs:{"value":"disagree"}},[_vm._v("否")])],1)],1)],1),_c('a-col',{attrs:{"span":"12"}},[_c('a-form-item',{attrs:{"label":"是否已逝世"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['dead',{
                initialValue: _vm.deathInfo.dead,
                rules: [
                  { required: true, message: '请选择是否已逝世' } ]}]),expression:"['dead',{\n                initialValue: deathInfo.dead,\n                rules: [\n                  { required: true, message: '请选择是否已逝世' },\n                ]}]"}],on:{"change":_vm.handleChangeDead}},[_c('a-radio',{attrs:{"value":true}},[_vm._v("是")]),_c('a-radio',{attrs:{"value":false}},[_vm._v("否")])],1)],1),(_vm.deathIsDead === true)?_c('a-form-item',{attrs:{"label":"逝世时间"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'death_date',
                {
                  initialValue: _vm.deathInfo.death_date,
                  rules: [{ required: true, message: '请选择逝世时间' }],
                } ]),expression:"[\n                'death_date',\n                {\n                  initialValue: deathInfo.death_date,\n                  rules: [{ required: true, message: '请选择逝世时间' }],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"format":"YYYY-MM-DD HH:mm","show-time":{ format: 'HH:mm' },"placeholder":"请选择逝世时间"},on:{"change":_vm.handleChange}})],1):_vm._e(),(_vm.deathIsDead === true)?_c('a-form-item',{attrs:{"label":"逝世原因"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['dead_reason', {
                initialValue: _vm.deathInfo.dead_reason,
                rules: [
                  { required: true, message: '请选择逝世原因' } ]
              }]),expression:"['dead_reason', {\n                initialValue: deathInfo.dead_reason,\n                rules: [\n                  { required: true, message: '请选择逝世原因' },\n                ]\n              }]"}],attrs:{"placeholder":"请选择逝世原因"}},_vm._l((_vm.deadReasonOptions),function(reason){return _c('a-select-option',{key:reason.value,attrs:{"value":reason.value}},[_vm._v(" "+_vm._s(reason.text)+" ")])}),1)],1):_vm._e(),(_vm.deathIsDead === true)?_c('a-form-item',{attrs:{"label":"逝者身上有无贵重物品"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['has_valuables',{
                initialValue: _vm.deathInfo.has_valuables,
                rules: [
                  { required: true, message: '请选择逝者身上有无贵重物品' } ]}]),expression:"['has_valuables',{\n                initialValue: deathInfo.has_valuables,\n                rules: [\n                  { required: true, message: '请选择逝者身上有无贵重物品' },\n                ]}]"}]},[_c('a-radio',{attrs:{"value":true}},[_vm._v("有")]),_c('a-radio',{attrs:{"value":false}},[_vm._v("无")])],1),_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['valuables_content', {
                initialValue: _vm.deathInfo.valuables_content,
                normalize: this.$lodash.trim,
                rules: [
                  { max: 300, message: '最多300个字符' } ]
              }]),expression:"['valuables_content', {\n                initialValue: deathInfo.valuables_content,\n                normalize: this.$lodash.trim,\n                rules: [\n                  { max: 300, message: '最多300个字符' },\n                ]\n              }]"}],attrs:{"placeholder":"若有，请仔细填写物品名称、数量(必填)，若因未填写清楚产生的法律问题纠纷，平台概不负责；若无则不需填写"}})],1):_vm._e(),(_vm.deathIsDead === true)?_c('a-form-item',{attrs:{"label":"逝者身上是否有伤口破损"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['damaged',{
                initialValue: _vm.deathInfo.damaged,
                rules: [
                  { required: true, message: '请选择逝者身上是否有伤口破损' } ]}]),expression:"['damaged',{\n                initialValue: deathInfo.damaged,\n                rules: [\n                  { required: true, message: '请选择逝者身上是否有伤口破损' },\n                ]}]"}]},[_c('a-radio',{attrs:{"value":true}},[_vm._v("有")]),_c('a-radio',{attrs:{"value":false}},[_vm._v("无")])],1),_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['damaged_content', {
                initialValue: _vm.deathInfo.damaged_content,
                normalize: this.$lodash.trim,
                rules: [
                  { max: 300, message: '最多300个字符' } ]
              }]),expression:"['damaged_content', {\n                initialValue: deathInfo.damaged_content,\n                normalize: this.$lodash.trim,\n                rules: [\n                  { max: 300, message: '最多300个字符' },\n                ]\n              }]"}],attrs:{"placeholder":"若有，请仔细描述伤口位置、数量(必填)，若因未填写清楚产生的法律问题纠纷，平台概不负责；若无则不需填写"}})],1):_vm._e(),_c('a-form-item',{attrs:{"label":"逝者父亲是否健在"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['father_living',{
                initialValue: _vm.deathInfo.father_living,
              }]),expression:"['father_living',{\n                initialValue: deathInfo.father_living,\n              }]"}]},[_c('a-radio',{attrs:{"value":"living"}},[_vm._v("是")]),_c('a-radio',{attrs:{"value":"dead"}},[_vm._v("否")])],1)],1),_c('a-form-item',{attrs:{"label":"逝者母亲是否健在"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['mother_living',{
                initialValue: _vm.deathInfo.mother_living,
              }]),expression:"['mother_living',{\n                initialValue: deathInfo.mother_living,\n              }]"}]},[_c('a-radio',{attrs:{"value":"living"}},[_vm._v("是")]),_c('a-radio',{attrs:{"value":"dead"}},[_vm._v("否")])],1)],1),_c('a-form-item',{attrs:{"label":"逝者配偶是否健在"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['partner_status',{
                initialValue: _vm.deathInfo.partner_status,
              }]),expression:"['partner_status',{\n                initialValue: deathInfo.partner_status,\n              }]"}]},[_c('a-radio',{attrs:{"value":"living"}},[_vm._v("是")]),_c('a-radio',{attrs:{"value":"dead"}},[_vm._v("否")]),_c('a-radio',{attrs:{"value":"none"}},[_vm._v("无配偶")])],1)],1),_c('a-form-item',{attrs:{"label":"逝者子女情况"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['children_info', {
                initialValue: _vm.deathInfo.children_info,
                normalize: this.$lodash.trim,
                rules: [
                  { max: 150, message: '最多150个字符' } ]
              }]),expression:"['children_info', {\n                initialValue: deathInfo.children_info,\n                normalize: this.$lodash.trim,\n                rules: [\n                  { max: 150, message: '最多150个字符' },\n                ]\n              }]"}],attrs:{"autocomplete":"off"}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }