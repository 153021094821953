import request from '@/utils/request'

// 家属关系表
export function findRelationTypeOptions() {
  return request({
    url: `genealogies/relation_type/options`,
    method: 'get'
  })
}

// 创建家属信息
export function createGenealogy(data) {
  return request({
    url: `/genealogies`,
    method: 'post',
    data
  })
}

// 删除家属信息
export function deleteGenealogy(id, data) {
  return request({
    url: `/genealogies/${id}`,
    method: 'delete',
    data
  })
}

// 更新家属信息
export function updateGenealogy(id, data) {
  return request({
    url: `/genealogies/${id}`,
    method: 'put',
    data
  })
}

// 查询配偶选项
export function findPartnerOptions(params) {
  return request({
    url: `genealogies/partner/options`,
    method: 'get',
    params: params
  })
}
