<template>
  <a-modal
    v-model="isShowIntroduction"
    centered
    :mask-closable="false"
    title="生平简介"
    :ok-button-props="{ style: { display: 'none' } }"
    :cancel-button-props="{ style: { margin: 'auto' } }"
    @cancel="cancelShowIntroduction"
  >
    <div>
      <a-textarea
          v-model:value="introduction.life_info"
          auto-size
          :bordered="false"
      />
    </div>
  </a-modal>
</template>

<script>
export default {
  name: 'Introduction',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    introduction: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  computed: {
    isShowIntroduction: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    cancelShowIntroduction() {
      this.isShowIntroduction = false
    }
  }
}
</script>
