<template>
  <div>
    <a-upload
      :accept="accept"
      name="file"
      :action="uploadData.upload_url"
      :data="uploadData"
      list-type="picture-card"
      :file-list="fileList"
      class="avatar-uploader"
      :multiple="multiple"
      :before-upload="handleBeforeUpload"
      @change="handleChange"
      @preview="handlePreview"
    >
      <div v-if="fileList.length < maxCount">
        <p class="ant-upload-plus-icon">
          <a-icon type="plus" />
        </p>
        <p class="ant-upload-text">
          点击上传
        </p>
      </div>
    </a-upload>

    <preview-video
      :visible.sync="previewVideoVisible"
      :video="previewVideo"
    />
  </div>
</template>

<script>
import { getResourceURL, getUploadToken, getUploadWatermarkToken } from '@/api/upload'
import PreviewVideo from '@/components/PreviewVideo'

export default {
  name: 'UploadVideo',
  components: { PreviewVideo },
  props: {
    // 已经上传的视频
    defaultFileList: {
      type: Array,
      default() {
        return []
      }
    },
    // 最多上传视频数量
    maxCount: {
      type: Number,
      default: 1
    },
    // 视频大小最大值(字节)
    maxSize: {
      type: Number,
      default: 10 * 1024 * 1024
    },
    accept: {
      type: String,
      default: 'video/*'
    },
    acceptValidateFunc: {
      type: Function,
      default: function() {
        return true
      }
    },
    multiple: {
      type: Boolean,
      default: false
    },
    isAddWatermark: {
      type: Boolean,
      default: false
    },
    // 存储方式 local（本地）/cos（腾讯云)
    storageMethod: {
      type: String,
      default: 'local'
    }
  },
  data() {
    return {
      fileList: this.defaultFileList,
      uploadData: {},
      previewVideoVisible: false,
      previewVideo: ''
    }
  },
  created() {
    this.getUploadToken()
  },
  watch: {
    defaultFileList(val) {
      this.fileList = val
    }
  },
  methods: {
    getUploadToken() {
      if (this.isAddWatermark) {
        getUploadWatermarkToken().then(res => {
          this.uploadData = res.data
          this.uploadData.storage_method_slug = this.storageMethod
        })
      } else {
        getUploadToken().then(res => {
          this.uploadData = res.data
          this.uploadData.storage_method_slug = this.storageMethod
        })
      }
    },

    triggerChange() {
      // Should provide an event to pass value to Form.
      this.$emit('change', this.fileList)
    },

    handleChange(info) {
      // 限制上传个数
      this.fileList = info.fileList.slice(-this.maxCount)
      this.triggerChange()
      setTimeout(() => {
        const classActions = document.getElementsByClassName('ant-upload-list-item-actions')
        classActions.forEach(ca => {
          ca.children[0].style.opacity = '1'
          ca.children[0].style.pointerEvents = 'initial'
        })
      }, 30)
    },

    handleBeforeUpload(file, fileList) {
      if (file.size > this.maxSize) {
        this.$message.error(`视频大小不能超过 ${this.maxSize / 1024.0 / 1024.0} MB`)
        file.status = 'error'
        return false
      }

      // 文件类型是否合法
      if (!this.acceptValidateFunc(file)) {
        this.$message.error('视频格式不正确')
        file.status = 'error'
        return false
      }
    },

    // 显示视频预览
    handlePreview(file) {
      getResourceURL(file.response).then((res) => {
        if (res.code === 0) {
          this.previewVideo = res.data.resource_url
          this.previewVideoVisible = true
        }
      })
    }
  }
}
</script>
