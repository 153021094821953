<template>
  <div>
    <a-table
        :columns="columns"
        :data-source="familyList"
        :loading="loading"
        :pagination="false"
        row-key="id"
    >
      <span slot="actions" slot-scope="text, record">
         <a-space>
           <a @click="showEditModal(record)">编辑</a>
            <a @click="addGenealogyModal(record)">新增家属</a>
           <a v-if="record.is_death === false" @click="deleteGenealogy(record.id)" class="custom-delete">删除</a>
         </a-space>
      </span>
    </a-table>
    <add-genealogy
        :genealogy-name="genealogyName"
        :genealogy-id="genealogyId"
        v-if="isShowAddGenealogyModal"
        :visible.sync="isShowAddGenealogyModal"
        @completed="fetchData"
    />
    <edit-genealogy
      :record="record"
      v-if="isShowEditGenealogyModal"
      :visible.sync="isShowEditGenealogyModal"
      @completed="fetchData"
    />
  </div>
</template>

<script>

import { findServiceOrderDeathFamilyInfo } from '@/api/order'
import EditGenealogy from '@/views/orders/tenant/deaths/EditGenealogy'
import AddGenealogy from "@/views/orders/tenant/deaths/AddGenealogy.vue";
import { deleteGenealogy } from '@/api/genealogy'
export default {
  name: 'FamilyInfo',
  components: {
    EditGenealogy,
    AddGenealogy
  },
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      familyInfo: {},
      isShowAddGenealogyModal: false,
      isShowEditGenealogyModal: false,
      deathId: 0,
      record: {},
      loading: false,
      familyList:[],
      genealogyId: 0,
      genealogyName: '',
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '姓名',
          width: 90,
          dataIndex: 'name'
        },
        {
          title:'性别',
          width: 60,
          dataIndex: 'sex_text'
        },
        {
          title:'联系方式',
          width: 90,
          dataIndex: 'phone_number'
        },
        {
          title:'身份证号',
          width: 120,
          dataIndex: 'id_no'
        },
        {
          title: '操作',
          width: 100,
          dataIndex: 'actions',
          scopedSlots: {customRender: 'actions'}
        }
      ]
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      findServiceOrderDeathFamilyInfo(this.id).then(res => {
        if (res.code === 0) {
          this.familyInfo = res.data
          this.deathId = res.data.service_order_death_id
          this.familyList = res.data.family_info
        }
        this.loading = false
      })
    },
    showEditModal(record) {
      this.isShowEditGenealogyModal = true
      this.record = record
    },
    addGenealogyModal(record) {
      this.isShowAddGenealogyModal = true
      this.genealogyId = record.id
      this.genealogyName = record.name
    },
    deleteGenealogy(id) {
      const vm = this
      this.$confirm({
        title: '确定删除吗？删除后无法进行恢复，请慎重操作',
        content: '',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          deleteGenealogy(id, { death_id: vm.deathId }).then((res) => {
            if (res.code === 0) {
              vm.fetchData()
            }
          })
        }
      })
    }
  }
}
</script>
