// 与逝者关系
export function relationOptions() {
  return ['父子', '父女', '母子', '母女', '夫妻', '孙子','孙女',
    '兄弟姐妹', '叔（姑）侄', '舅（姨）甥', '朋友', '同事'
  ]
}

export function religionOptions() {
  return ['无', '佛教', '道教', '基督教', '伊斯兰教']
}

export function sexOption() {
  return [{
    text: '男',
    value: 0
  }, {
    text: '女',
    value: 1
  }]
}

export function deadReasonOptions() {
  return [{
    value: 'natural',
    text: '自然死亡'
  },
  {
    value: 'illness',
    text: '病逝'
  },
  {
    value: 'traffic',
    text: '交通事故'
  },
  {
    value: 'unforeseen',
    text: '意外事故'
  },
  {
    value: 'other',
    text: '其他'
  }
  ]
}
