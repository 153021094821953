/**
 *  身份证计算年龄
 */
export function calDeathAge(val) {
  if (val === '') {
    return ''
  }
  let yearBirth
  let monthBirth
  let dayBirth
  if (val.length === 15) {
    const org_birthday = val.substring(6, 12)
    // 获取出生年月日
    yearBirth = '19' + org_birthday.substring(0, 2)
    monthBirth = org_birthday.substring(2, 4)
    dayBirth = org_birthday.substring(4, 6)
  } else if (val.length === 18) {
    // 获取出生年月日
    yearBirth = val.substring(6, 10)
    monthBirth = val.substring(10, 12)
    dayBirth = val.substring(12, 14)
  } else {
    return ''
  }
  // 获取当前年月日并计算年龄
  const myDate = new Date()
  const monthNow = myDate.getMonth() + 1
  const dayNow = myDate.getDate()
  let age = myDate.getFullYear() - yearBirth
  if (monthNow < monthBirth || (monthNow == monthBirth && dayNow < dayBirth)) {
    age--
  }
  // 返回年龄
  return age
}
