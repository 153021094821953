<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="编辑逝者信息"
      @ok="handleSubmit"
      width="1300px"
    >
      <a-form
        class="custom-compact-form"
        :form="form"
        :label-col="{ span: 7 }"
        :wrapper-col="{ span: 13 }"
        @submit="handleSubmit"
      >
        <a-row>
          <a-col span="12">
            <a-form-item label="逝者姓名">
              <a-input
                v-decorator="['name', {
                  initialValue: deathInfo.name,
                  normalize: this.$lodash.trim,
                  rules: [
                    { required: true, message: '请输入逝者姓名' },
                    { max: 30, message: '最多30个字符' },
                  ]
                }]"
                autocomplete="off"
              />
            </a-form-item>
            <a-form-item label="身份证号">
              <a-input
                v-decorator="['identity_card_no', {
                  initialValue: deathInfo.identity_card_no,
                  normalize: this.$lodash.trim,
                  rules: [
                    { required: true, message: '请输入身份证号' },
                    { pattern: /^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/, message: '身份证号格式不正确' },
                  ]
                }]"
                @change="calAge"
                autocomplete="off"
              />
            </a-form-item>
            <a-form-item label="性别">
              <a-radio-group
                v-decorator="['sex',{
                  initialValue: deathInfo.sex,
                  rules: [
                    { required: true, message: '请选择逝者性别' },
                  ]}]"
              >
                <a-radio v-for="(option,index) in sexOptions" :value="option.value" :key="index">{{ option.text }}</a-radio>
              </a-radio-group>
            </a-form-item>
            <a-form-item label="年龄">
              <a-input-number
                v-decorator="['age', {
                  initialValue: deathInfo.age,
                  normalize: this.$lodash.trim,
                  rules: [
                    { required: true, message: '请输入逝者年龄' },
                    { pattern: /^([1-9][0-9]*)$/, message: '请输入逝者年龄' },
                  ]
                }]"
                :min="1"
                :max="999"
              />
            </a-form-item>
            <a-form-item label="宗教信仰">
              <a-select
                v-decorator="['religion',{
                  initialValue: deathInfo.religion,
                  rules: [ { required: true, message: '请选择宗教信仰' },]
                }]"
                placeholder="请选择宗教信仰"
                allow-clear
                mode="combobox"
              >
                <a-select-option
                  v-for="name in religionOptions"
                  :key="name"
                  :value="name"
                >
                  {{ name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="出生日期">
              <a-date-picker
                style="width: 100%;"
                v-decorator="[
                  'birth_day',{
                    initialValue: deathInfo.birth_day,
                  }
                ]"
                placeholder="请选择出生日期"
              />
            </a-form-item>
            <a-form-item label="是否为党员">
              <a-radio-group
                v-decorator="['party_member',{
                  initialValue: deathInfo.party_member,
                }]"
              >
                <a-radio value="yes">是</a-radio>
                <a-radio value="no">否</a-radio>
              </a-radio-group>
            </a-form-item>
            <a-form-item label="逝者职业">
              <a-input
                v-decorator="['occupation', {
                  initialValue: deathInfo.occupation,
                  normalize: this.$lodash.trim,
                  rules: [
                    { max: 30, message: '最多30个字符' },
                  ]
                }]"
                autocomplete="off"
              />
            </a-form-item>
            <a-form-item label="家庭住址">
              <a-input
                v-decorator="['address', {
                  initialValue: deathInfo.address,
                  normalize: this.$lodash.trim,
                  rules: [
                    { max: 100, message: '最多100个字符' },
                  ]
                }]"
                autocomplete="off"
              />
            </a-form-item>
            <a-form-item label="是否行三跪九叩首大礼">
              <a-radio-group
                v-decorator="['san_gui_jiu_kou',{
                  initialValue: deathInfo.san_gui_jiu_kou,
                }]"
              >
                <a-radio value="agree">是</a-radio>
                <a-radio value="disagree">否</a-radio>
              </a-radio-group>
            </a-form-item>
            <a-form-item label="家属取抽身布">
              <a-radio-group
                v-decorator="['pull_out_cloth',{
                  initialValue: deathInfo.pull_out_cloth,
                }]"
              >
                <a-radio value="agree">是</a-radio>
                <a-radio value="disagree">否</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
          <a-col span="12">
            <a-form-item label="是否已逝世">
              <a-radio-group
                v-decorator="['dead',{
                  initialValue: deathInfo.dead,
                  rules: [
                    { required: true, message: '请选择是否已逝世' },
                  ]}]"
                @change="handleChangeDead"
              >
                <a-radio :value="true">是</a-radio>
                <a-radio :value="false">否</a-radio>
              </a-radio-group>
            </a-form-item>
            <a-form-item label="逝世时间" v-if="deathIsDead === true">
              <a-date-picker
                style="width: 100%;"
                v-decorator="[
                  'death_date',
                  {
                    initialValue: deathInfo.death_date,
                    rules: [{ required: true, message: '请选择逝世时间' }],
                  },
                ]"
                format="YYYY-MM-DD HH:mm"
                :show-time="{ format: 'HH:mm' }"
                @change="handleChange"
                placeholder="请选择逝世时间"
              />
            </a-form-item>
            <a-form-item label="逝世原因" v-if="deathIsDead === true">
              <a-select
                v-decorator="['dead_reason', {
                  initialValue: deathInfo.dead_reason,
                  rules: [
                    { required: true, message: '请选择逝世原因' },
                  ]
                }]"
                placeholder="请选择逝世原因"
              >
                <a-select-option
                  v-for="reason in deadReasonOptions"
                  :key="reason.value"
                  :value="reason.value"
                >
                  {{ reason.text }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="逝者身上有无贵重物品" v-if="deathIsDead === true">
              <a-radio-group
                v-decorator="['has_valuables',{
                  initialValue: deathInfo.has_valuables,
                  rules: [
                    { required: true, message: '请选择逝者身上有无贵重物品' },
                  ]}]"
              >
                <a-radio :value="true">有</a-radio>
                <a-radio :value="false">无</a-radio>
              </a-radio-group>
              <a-textarea
                v-decorator="['valuables_content', {
                  initialValue: deathInfo.valuables_content,
                  normalize: this.$lodash.trim,
                  rules: [
                    { max: 300, message: '最多300个字符' },
                  ]
                }]"
                placeholder="若有，请仔细填写物品名称、数量(必填)，若因未填写清楚产生的法律问题纠纷，平台概不负责；若无则不需填写"
              />
            </a-form-item>
            <a-form-item label="逝者身上是否有伤口破损" v-if="deathIsDead === true">
              <a-radio-group
                v-decorator="['damaged',{
                  initialValue: deathInfo.damaged,
                  rules: [
                    { required: true, message: '请选择逝者身上是否有伤口破损' },
                  ]}]"
              >
                <a-radio :value="true">有</a-radio>
                <a-radio :value="false">无</a-radio>
              </a-radio-group>
              <a-textarea
                v-decorator="['damaged_content', {
                  initialValue: deathInfo.damaged_content,
                  normalize: this.$lodash.trim,
                  rules: [
                    { max: 300, message: '最多300个字符' },
                  ]
                }]"
                placeholder="若有，请仔细描述伤口位置、数量(必填)，若因未填写清楚产生的法律问题纠纷，平台概不负责；若无则不需填写"
              />
            </a-form-item>
            <a-form-item label="逝者父亲是否健在">
              <a-radio-group
                v-decorator="['father_living',{
                  initialValue: deathInfo.father_living,
                }]"
              >
                <a-radio value="living">是</a-radio>
                <a-radio value="dead">否</a-radio>
              </a-radio-group>
            </a-form-item>
            <a-form-item label="逝者母亲是否健在">
              <a-radio-group
                v-decorator="['mother_living',{
                  initialValue: deathInfo.mother_living,
                }]"
              >
                <a-radio value="living">是</a-radio>
                <a-radio value="dead">否</a-radio>
              </a-radio-group>
            </a-form-item>
            <a-form-item label="逝者配偶是否健在">
              <a-radio-group
                v-decorator="['partner_status',{
                  initialValue: deathInfo.partner_status,
                }]"
              >
                <a-radio value="living">是</a-radio>
                <a-radio value="dead">否</a-radio>
                <a-radio value="none">无配偶</a-radio>
              </a-radio-group>
            </a-form-item>
            <a-form-item label="逝者子女情况">
              <a-input
                v-decorator="['children_info', {
                  initialValue: deathInfo.children_info,
                  normalize: this.$lodash.trim,
                  rules: [
                    { max: 150, message: '最多150个字符' },
                  ]
                }]"
                autocomplete="off"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { updateDeathInfo } from '@/api/service_order_death'
import { deadReasonOptions, religionOptions, sexOption } from '@/utils/enum'
import { alertMessage } from '@/utils/alert_message'
import { formatDateTimeMin } from '@/utils/time'
import { calDeathAge } from '@/utils/idcard'

export default {
  name: 'EditServiceOrderDeathInfo',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    deathInfo: {
      type: Object,
      required: true
    },
    deathId: {
      type: Number,
      required: true
    },
    isDead: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'Destination' }),
      submitting: false,
      data: {},
      sexOptions: sexOption(),
      religionOptions: religionOptions(),
      deadReasonOptions: deadReasonOptions(),
      deathTime: '',
      deathIsDead: false
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.deathIsDead = this.isDead
    this.deathTime = this.deathInfo.death_date
  },
  methods: {
    calAge(e) {
      this.$nextTick(() => {
        this.form.setFieldsValue({ age: calDeathAge(e.target.value) })
      })
    },
    handleChangeDead(e) {
      if (e.target.value === true) {
        this.deathIsDead = true
      } else if (e.target.value === false) {
        this.deathIsDead = false
      }
    },
    handleChange(value) {
      this.value = value
      this.deathTime = formatDateTimeMin(value)
    },
    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          if (values.has_valuables === true && values.valuables_content === '') {
            alertMessage({
              type: 'error',
              content: '请仔细填写逝者身上填写物品名称、数量'
            })
            this.submitting = false
            return
          }
          if (values.damaged === true && values.damaged_content === '') {
            alertMessage({
              type: 'error',
              content: '请仔细描述逝者身上描述伤口位置、数量'
            })
            this.submitting = false
            return
          }
          values.death_date = this.deathTime
          updateDeathInfo(this.deathId, values).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
