<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="编辑家属"
      @ok="handleSubmit"
    >
      <a-form
        class="custom-compact-form"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >
        <a-form-item label="家属姓名">
          <a-input
            v-decorator="['name', {
              initialValue: record.name,
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入家属姓名' },
                { max: 100, message: '最多100个字符' },
              ]
            }]"
            autocomplete="off"
            :disabled="record.is_death"
          />
        </a-form-item>
        <a-form-item label="性别">
          <a-radio-group
              disabled
            v-decorator="['sex',{
              initialValue: record.sex,
              rules: [
                { required: true, message: '请选择逝者性别' },
              ]}]"
          >
            <a-radio v-for="(option,index) in sexOptions" :value="option.value" :key="index">{{ option.text }}</a-radio>
          </a-radio-group>
        </a-form-item>

        <a-form-item label="联系电话">
          <a-input
            v-decorator="['phone_number', {
              initialValue: record.phone_number,
              normalize: this.$lodash.trim,
              rules: [
                { required: false, message: '请输入联系电话' },
                { pattern: /^1[3-9]\d{9}$/, message: '联系电话格式不正确' },
                { max: 11, message: '联系电话最多11位'},
              ]
            }]"
            :disabled="record.is_death"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="身份证号">
          <a-input
            v-decorator="['identity_card_no', {
              initialValue: record.id_no,
              normalize: this.$lodash.trim,
              rules: [
               { validator: validIdNo},
                { pattern: /^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/, message: '身份证号格式不正确' },
              ]
            }]"
            :disabled="record.is_death"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="已逝">
          <a-radio-group :disabled="record.is_death" v-decorator="['dead', { initialValue: record.dead }]">
            <a-radio :value="true">是</a-radio>
            <a-radio :value="false">否</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="家中排行">
          <a-input-number :min="1" v-decorator="['sort_num', {initialValue: record.sort_num}]"></a-input-number>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { createGenealogy, findRelationTypeOptions, updateGenealogy } from '@/api/genealogy'
import { sexOption } from '@/utils/enum'
import { findChildrenOptions, updateDeathInfo } from '@/api/service_order_death'

export default {
  name: 'EditGenealogy',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    record: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'edit-genealogy' }),
      submitting: false,
      relationTypeOptions: [],
      sexOptions: sexOption(),
      childrenOptions: [],
      relationType: ''
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.relationType = this.record.relation_type_slug
  },
  methods: {
    validIdNo(rule, value,callback) {
      let dead = this.form.getFieldValue('dead')
      if (dead === false && (value === '' || value === undefined)){
        callback(new Error('请输入身份证号码'))
      }else{
        callback()
      }
    },
    changeRelationType(e) {
      this.relationType = e
    },
    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          const data = {
            ...values
          }
          updateGenealogy(this.record.id, data).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    },
  }
}
</script>

<style scoped>

</style>
