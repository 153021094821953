<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      :title="title"
      @ok="handleSubmit"
    >
      <a-form
        class="custom-compact-form"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >
        <a-form-item label="姓名">
          <a-input disabled v-decorator="['base_name', { initialValue:  genealogyName}]"></a-input>
        </a-form-item>
        <a-form-item label="家属关系">
          <a-select
            v-decorator="['relation_type', {
              rules: [
                { required: true, message: '请选择家属关系' },
              ]
            }]"
            placeholder="请选择家属关系"
            @change="changeRelationType"
          >
            <a-select-option
              v-for="relation in relationTypeOptions"
              :key="relation.slug"
              :value="relation.slug"
            >

              {{ relation.text }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="家属姓名">
          <a-input
            v-decorator="['name', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入家属姓名' },
                { max: 100, message: '最多100个字符' },
              ]
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="性别">
          <a-radio-group
            v-decorator="['sex',{
              rules: [
                { required: true, message: '请选择性别' },
              ]}]"
          >
            <a-radio v-for="(option,index) in sexOptions" :value="option.value" :key="index">{{ option.text }}</a-radio>
          </a-radio-group>
        </a-form-item>

        <a-form-item label="联系电话">
          <a-input
            v-decorator="['phone_number', {
              normalize: this.$lodash.trim,
              rules: [
                { required: false, message: '请输入联系电话' },
                { pattern: /^1[3-9]\d{9}$/, message: '联系电话格式不正确' },
                { max: 11, message: '联系电话最多11位'},
              ]
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="身份证号">
          <a-input
            v-decorator="['identity_card_no', {
              normalize: this.$lodash.trim,
              rules: [
                { pattern: /^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/, message: '身份证号格式不正确' },
                { validator: validIdNo}
              ]
            }]"
            autocomplete="off"
          />
        </a-form-item>

        <a-form-item label="已逝">
          <a-radio-group v-decorator="['dead', { initialValue: false }]">
            <a-radio :value="true">是</a-radio>
            <a-radio :value="false">否</a-radio>
          </a-radio-group>
        </a-form-item>

        <a-form-item label="家中排行">
          <a-input-number :min="1" v-decorator="['sort_num', {initialValue: 1}]"></a-input-number>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import {createGenealogy, findPartnerOptions, findRelationTypeOptions} from '@/api/genealogy'
import { sexOption } from '@/utils/enum'
import { findChildrenOptions, updateDeathInfo } from '@/api/service_order_death'

export default {
  name: 'AddGenealogy',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    genealogyId: {
      type: Number,
      required: true
    },
    genealogyName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'add-genealogy' }),
      submitting: false,
      relationTypeOptions: [
        {slug:'parent',text:'父母'},
        {slug:'children',text:'子女'},
        {slug: 'spouse', text:'配偶'},
      ],
      sexOptions: sexOption(),
      childrenOptions: [],
      relationType: '',
      partnerOptions: [],// 配偶
      title: '新增'+this.genealogyName+'家属',
      masterShow: false,
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },
  },
  created() {
    // this.fetchRelationTypeOptions()
  },
  methods: {
    validIdNo(rule, value,callback) {
      let dead = this.form.getFieldValue('dead')
      if (dead === false && (value === '' || value === undefined)){
        callback(new Error('请输入身份证号码'))
      }else{
        callback()
      }
    },

    changeRelationType(e) {
      this.relationType = e
      if (this.relationType === 'grandchildren' || this.relationType === 'children' || this.relationType === 'brothers_and_sisters'){
        this.form.setFieldsValue({partner_id: undefined})
      }
    },
    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          const data = {
            ...values,
            genealogy_id: this.genealogyId
          }
          createGenealogy(data).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    },
    fetchRelationTypeOptions() {
      findRelationTypeOptions().then((res) => {
        if (res.code === 0) {
          this.relationTypeOptions = res.data
        }
      })
    },
    fetchChildrenOptions() {
      findChildrenOptions(this.deathId).then((res) => {
        if (res.code === 0) {
          this.childrenOptions = res.data
        }
      })
    },
    fetchPartnerOptions() {
      let relation_type = this.form.getFieldValue('relation_type')
      let sex = this.form.getFieldValue('sex')
      this.partnerOptions = []
      if (relation_type != undefined && sex != undefined) {
        findPartnerOptions({relation_type: relation_type,death_id: this.deathId,sex: sex}).then(res => {
          if (res.code === 0) {
            this.partnerOptions = res.data
          }
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
