var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"centered":"","mask-closable":false,"ok-button-props":{ props: { loading: _vm.submitting } },"title":_vm.title},on:{"ok":_vm.handleSubmit},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('a-form',{staticClass:"custom-compact-form",attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 15 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"姓名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['base_name', { initialValue:  _vm.genealogyName}]),expression:"['base_name', { initialValue:  genealogyName}]"}],attrs:{"disabled":""}})],1),_c('a-form-item',{attrs:{"label":"家属关系"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['relation_type', {
            rules: [
              { required: true, message: '请选择家属关系' } ]
          }]),expression:"['relation_type', {\n            rules: [\n              { required: true, message: '请选择家属关系' },\n            ]\n          }]"}],attrs:{"placeholder":"请选择家属关系"},on:{"change":_vm.changeRelationType}},_vm._l((_vm.relationTypeOptions),function(relation){return _c('a-select-option',{key:relation.slug,attrs:{"value":relation.slug}},[_vm._v(" "+_vm._s(relation.text)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"家属姓名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['name', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入家属姓名' },
              { max: 100, message: '最多100个字符' } ]
          }]),expression:"['name', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { required: true, message: '请输入家属姓名' },\n              { max: 100, message: '最多100个字符' },\n            ]\n          }]"}],attrs:{"autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":"性别"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['sex',{
            rules: [
              { required: true, message: '请选择性别' } ]}]),expression:"['sex',{\n            rules: [\n              { required: true, message: '请选择性别' },\n            ]}]"}]},_vm._l((_vm.sexOptions),function(option,index){return _c('a-radio',{key:index,attrs:{"value":option.value}},[_vm._v(_vm._s(option.text))])}),1)],1),_c('a-form-item',{attrs:{"label":"联系电话"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['phone_number', {
            normalize: this.$lodash.trim,
            rules: [
              { required: false, message: '请输入联系电话' },
              { pattern: /^1[3-9]\d{9}$/, message: '联系电话格式不正确' },
              { max: 11, message: '联系电话最多11位'} ]
          }]),expression:"['phone_number', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { required: false, message: '请输入联系电话' },\n              { pattern: /^1[3-9]\\d{9}$/, message: '联系电话格式不正确' },\n              { max: 11, message: '联系电话最多11位'},\n            ]\n          }]"}],attrs:{"autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":"身份证号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['identity_card_no', {
            normalize: this.$lodash.trim,
            rules: [
              { pattern: /^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/, message: '身份证号格式不正确' },
              { validator: _vm.validIdNo}
            ]
          }]),expression:"['identity_card_no', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { pattern: /^[1-9]\\d{5}[1-9]\\d{3}((0\\d)|(1[0-2]))(([0|1|2]\\d)|3[0-1])\\d{3}([0-9]|X)$/, message: '身份证号格式不正确' },\n              { validator: validIdNo}\n            ]\n          }]"}],attrs:{"autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":"已逝"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['dead', { initialValue: false }]),expression:"['dead', { initialValue: false }]"}]},[_c('a-radio',{attrs:{"value":true}},[_vm._v("是")]),_c('a-radio',{attrs:{"value":false}},[_vm._v("否")])],1)],1),_c('a-form-item',{attrs:{"label":"家中排行"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['sort_num', {initialValue: 1}]),expression:"['sort_num', {initialValue: 1}]"}],attrs:{"min":1}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }