var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"centered":"","mask-closable":false,"ok-button-props":{ props: { loading: _vm.submitting } },"title":"编辑家属"},on:{"ok":_vm.handleSubmit},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('a-form',{staticClass:"custom-compact-form",attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 15 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"家属姓名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['name', {
            initialValue: _vm.record.name,
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入家属姓名' },
              { max: 100, message: '最多100个字符' } ]
          }]),expression:"['name', {\n            initialValue: record.name,\n            normalize: this.$lodash.trim,\n            rules: [\n              { required: true, message: '请输入家属姓名' },\n              { max: 100, message: '最多100个字符' },\n            ]\n          }]"}],attrs:{"autocomplete":"off","disabled":_vm.record.is_death}})],1),_c('a-form-item',{attrs:{"label":"性别"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['sex',{
            initialValue: _vm.record.sex,
            rules: [
              { required: true, message: '请选择逝者性别' } ]}]),expression:"['sex',{\n            initialValue: record.sex,\n            rules: [\n              { required: true, message: '请选择逝者性别' },\n            ]}]"}],attrs:{"disabled":""}},_vm._l((_vm.sexOptions),function(option,index){return _c('a-radio',{key:index,attrs:{"value":option.value}},[_vm._v(_vm._s(option.text))])}),1)],1),_c('a-form-item',{attrs:{"label":"联系电话"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['phone_number', {
            initialValue: _vm.record.phone_number,
            normalize: this.$lodash.trim,
            rules: [
              { required: false, message: '请输入联系电话' },
              { pattern: /^1[3-9]\d{9}$/, message: '联系电话格式不正确' },
              { max: 11, message: '联系电话最多11位'} ]
          }]),expression:"['phone_number', {\n            initialValue: record.phone_number,\n            normalize: this.$lodash.trim,\n            rules: [\n              { required: false, message: '请输入联系电话' },\n              { pattern: /^1[3-9]\\d{9}$/, message: '联系电话格式不正确' },\n              { max: 11, message: '联系电话最多11位'},\n            ]\n          }]"}],attrs:{"disabled":_vm.record.is_death,"autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":"身份证号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['identity_card_no', {
            initialValue: _vm.record.id_no,
            normalize: this.$lodash.trim,
            rules: [
             { validator: _vm.validIdNo},
              { pattern: /^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/, message: '身份证号格式不正确' } ]
          }]),expression:"['identity_card_no', {\n            initialValue: record.id_no,\n            normalize: this.$lodash.trim,\n            rules: [\n             { validator: validIdNo},\n              { pattern: /^[1-9]\\d{5}[1-9]\\d{3}((0\\d)|(1[0-2]))(([0|1|2]\\d)|3[0-1])\\d{3}([0-9]|X)$/, message: '身份证号格式不正确' },\n            ]\n          }]"}],attrs:{"disabled":_vm.record.is_death,"autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":"已逝"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['dead', { initialValue: _vm.record.dead }]),expression:"['dead', { initialValue: record.dead }]"}],attrs:{"disabled":_vm.record.is_death}},[_c('a-radio',{attrs:{"value":true}},[_vm._v("是")]),_c('a-radio',{attrs:{"value":false}},[_vm._v("否")])],1)],1),_c('a-form-item',{attrs:{"label":"家中排行"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['sort_num', {initialValue: _vm.record.sort_num}]),expression:"['sort_num', {initialValue: record.sort_num}]"}],attrs:{"min":1}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }