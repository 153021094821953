<template>
  <div>
    <a-tabs
      type="card"
      v-decorator="['tags', {
      }]"
      :active-key="tableKey"
      @change="handleChange"
    >
      <a-tab-pane key="basic" tab="基本信息" class="tab-pane-scroll">
        <basic-info
          :id="id"
          ref="basic"
        />
      </a-tab-pane>
      <a-tab-pane key="family" tab="家属信息" class="tab-pane-scroll">
        <family-info
          :id="id"
          ref="family"
        />
      </a-tab-pane>
      <a-tab-pane key="genealogy" tab="家族谱" class="tab-pane-scroll">
        <genealogy ref="genealogy" :id="id" />
      </a-tab-pane>
      <div slot="tabBarExtraContent">
        <a-button v-if="tableKey==='basic'" type="primary" @click="fetchServiceOrderDeathBasicInfoData">编辑</a-button>
<!--        <a-button v-if="tableKey==='family'" type="primary" @click="fetchServiceOrderDeathFamilyInfoData">新增家属</a-button>-->
      </div>
    </a-tabs>
    <edit-death-info
      v-if="typeof isDead === 'boolean'"
      :visible.sync="isShowEditDeathInfo"
      :death-info="deathEditData"
      :death-id="serviceOrderDeathId"
      :is-dead="isDead"
      @completed="fetchBasicData"
    />
  </div>
</template>

<script>
import BasicInfo from '@/views/orders/tenant/deaths/BasicInfo'
import familyInfo from '@/views/orders/tenant/deaths/FamilyInfo'
import Genealogy from '@/components/genealogy/genealogy'
import { findDeathEditForm } from '@/api/service_order_death'
import { findServiceOrderDeathBasicInfo, findServiceOrderDeathFamilyInfo } from '@/api/order'
import EditDeathInfo from '@/views/orders/tenant/deaths/EditDeathInfo'
import AddGenealogy from '@/views/orders/tenant/deaths/AddGenealogy'

export default {
  name: 'ShowDeathInfo',
  components: {
    BasicInfo,
    familyInfo,
    Genealogy,
    EditDeathInfo,
    AddGenealogy
  },
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      data: {},
      loading: true,
      tableKey: 'basic', // 当前标签名字
      isShowEditDeathInfo: false,
      deathEditData: {},
      serviceOrderDeathId: 0,
      isDead: '',
      deathId: 0,
      familyInfo: {},
      isShowAddGenealogyModal: false
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  mounted() {
    this.fetchBasicData()
  },
  methods: {
    handleChange(e) {
      this.tableKey = e
      this.$nextTick(() => {
        if (e === 'genealogy') {
          this.fetchGenealogyData()
        } else if (e === 'family') {
          this.fetchFamilyData()
        } else if (e === 'basic') {
          this.fetchBasicData()
        }
      })
      return {
        tab: e.tab,
        index: e.index
      }
    },
    showEditDeathInfo() {
      this.isShowEditDeathInfo = true
    },
    showAddGenealogyModal() {
      this.isShowAddGenealogyModal = true
    },
    fetchServiceOrderDeathBasicInfoData() {
      findServiceOrderDeathBasicInfo(this.id).then(res => {
        if (res.code === 0) {
          this.serviceOrderDeathId = res.data.id
          this.fetchDeathEditData()
          this.showEditDeathInfo()
        }
      })
    },
    fetchDeathEditData() {
      findDeathEditForm(this.serviceOrderDeathId).then((res) => {
        if (res.code === 0) {
          this.deathEditData = res.data
          this.isDead = res.data.dead
        }
      })
    },
    fetchServiceOrderDeathFamilyInfoData() {
      findServiceOrderDeathFamilyInfo(this.id).then(res => {
        if (res.code === 0) {
          this.familyInfo = res.data
          this.deathId = res.data.service_order_death_id
          this.showAddGenealogyModal()
        }
      })
    },
    fetchBasicData() {
      this.$refs.basic.fetchData()
    },
    fetchFamilyData() {
      this.$refs.family.fetchData()
    },
    fetchGenealogyData() {
      this.$refs.genealogy.fetchData()
    }
  }
}
</script>

<style lang="less" scoped>
.custom-blue {
  cursor: pointer;
}

.tab-pane-scroll {
  height: 443px;
  overflow: auto;
}
.tree-info {
  padding: 20px;
  height: 400px;
}
</style>
