<template>
  <div>
    <a-row>
      <a-col :span="8">
        <a-descriptions
          :column="1"
          class="custom-descriptions custom-descriptions-max-10"
        >
          <a-descriptions-item label="姓名">{{ basicInfo.name }}</a-descriptions-item>
          <a-descriptions-item label="身份证号">{{ basicInfo.identity_card_no }}</a-descriptions-item>
          <a-descriptions-item label="性别">{{ basicInfo.sex }}</a-descriptions-item>
          <a-descriptions-item label="年龄">{{ basicInfo.age }}</a-descriptions-item>
          <a-descriptions-item label="宗教信仰">{{ basicInfo.religion }}</a-descriptions-item>
          <a-descriptions-item label="出生时间">{{ basicInfo.birth_day }}</a-descriptions-item>
          <a-descriptions-item label="是否已逝世">{{ basicInfo.dead }}</a-descriptions-item>
          <a-descriptions-item v-if="basicInfo.is_dead" label="逝世时间">{{ basicInfo.death_date }}</a-descriptions-item>
          <a-descriptions-item v-if="basicInfo.is_dead" label="逝世原因">{{ basicInfo.dead_reason }}</a-descriptions-item>
          <a-descriptions-item v-if="basicInfo.is_dead" label="逝者身上是否有贵重物品">{{ basicInfo.has_valuables ? '有' : '无' }}</a-descriptions-item>
          <a-descriptions-item v-if="basicInfo.has_valuables">{{ basicInfo.valuables_content }}</a-descriptions-item>
          <a-descriptions-item v-if="basicInfo.is_dead" label="逝者身上是否有伤口破损">{{ basicInfo.damaged ? '有' : '无' }}</a-descriptions-item>
          <a-descriptions-item v-if="basicInfo.damaged">{{ basicInfo.damage_desc }}</a-descriptions-item>
        </a-descriptions>
      </a-col>

      <a-col :span="8">
        <a-descriptions
          :column="1"
          class="custom-descriptions custom-descriptions-max-6"
        >
          <a-descriptions-item label="是否为党员">{{ basicInfo.party_member }}</a-descriptions-item>
          <a-descriptions-item label="逝世职业">{{ basicInfo.occupation }}</a-descriptions-item>
          <a-descriptions-item label="家庭住址">{{ basicInfo.address }}</a-descriptions-item>
          <a-descriptions-item label="肖像照片">
            <a-space>
              <span v-if="basicInfo.images === null || defaultImages.length === 0">暂无图片</span>
              <a @click="ShowImgEdit()">上传</a>
            </a-space>
            <a-modal
              :visible="isShowImgEdit"
              centered
              :mask-closable="false"
              title="图片编辑"
              @ok="handleSubmitImg"
              @cancel="cancelEditImg"
            >
              <a-form
                class="custom-compact-form"
                :form="form"
                :wrapper-col="{ span: 24 }"
                @submit="handleSubmitImg"
              >
                <a-form-item>
                  <div>支持png/jpg格式照片，10M以内, 最多9张
                  </div>
                  <upload-image
                    accept=".jpg,.jpeg,.png"
                    :max-count="9"
                    :multiple="true"
                    :default-file-list="defaultFileList"
                    @change="handleUploadImage"
                    v-decorator="['attachments',{
                      rules:[
                        { validator: checkAttachments }
                      ]
                    }]"
                  />
                </a-form-item>
              </a-form>
            </a-modal>
          </a-descriptions-item>
        </a-descriptions>
        <a-descriptions
          :column="1"
          class="custom-descriptions custom-descriptions-max-6"
          v-if="basicInfo.images && defaultImages.length > 0"
        >
          <div>
            <div class="custom-flex custom-flex-wrap">
              <div v-for="(img, index) in basicInfo.images" :key="index">
                <img :src="img.url" @click="showImg(img.url)" width="50px" height="50px" style="margin: 5px">
              </div>
            </div>
          </div>
        </a-descriptions>
        <a-descriptions
          :column="1"
          class="custom-descriptions custom-descriptions-max-6"
        >
          <a-descriptions-item label="视频">
            <a-space>
              <span v-if="!basicInfo.videos || defaultVideos.length === 0">暂无视频</span>
              <a @click="ShowVideoEdit">上传</a>
            </a-space>
            <a-modal
              :visible="isShowVideoEdit"
              centered
              :mask-closable="false"
              title="视频编辑"
              :key="editVideoNum"
              @ok="handleSubmitVideo"
              @cancel="cancelEditVideo"
            >
              <a-form
                class="custom-compact-form"
                :form="form"
                :wrapper-col="{ span: 24 }"
                @submit="handleSubmitVideo"
              >
                <a-form-item>
                  <div>支持MP4/AVI格式视频，1G以内, 最多9个
                  </div>
                  <upload-video
                    accept=".mp4,.avi"
                    :max-count="9"
                    :multiple="true"
                    :max-size="1024 *1024 *1024"
                    :default-file-list="defaultVideoFileList"
                    @change="handleUploadVideo"
                    v-decorator="['videoAttachments',{
                      rules:[
                        {validator: checkVideoAttachments}
                      ]
                    }]"
                  />
                </a-form-item>
              </a-form>
            </a-modal>
          </a-descriptions-item>
        </a-descriptions>
        <a-descriptions
          :column="1"
          class="custom-descriptions custom-descriptions-max-6"
          v-if="basicInfo.videos && defaultVideos.length > 0"
        >
          <div>
            <div class="custom-flex custom-flex-wrap">
              <div v-for="(img, index) in basicInfo.videos" :key="index" class="video">
                <img src="/temp/logo.png" width="50px" height="50px" @click="showVideo(img.url)">
              </div>
            </div>
          </div>
        </a-descriptions>
        <a-descriptions
          :column="1"
          class="custom-descriptions custom-descriptions-max-6"
        >
          <a-descriptions-item label="逝者生平简介">
            <a-space>
              <a @click="showIntroduction">查看详情</a>
              <a @click="showEditLifeInfo(basicInfo.id)">编辑</a>
            </a-space>
            <introduction
              v-if="isShowIntroduction"
              :introduction="basicInfo"
              :visible.sync="isShowIntroduction"
            />
          </a-descriptions-item>
          <a-descriptions-item label="证件资料">
            <a-space>
              <span v-if="basicInfo.documents === null || defaultDocuments.length === 0">暂无图片</span>
              <a @click="ShowDocumentEdit">上传</a>
            </a-space>
            <a-modal
              :visible="isShowDocumentEdit"
              centered
              :mask-closable="false"
              title="证件资料编辑"
              @ok="handleSubmitDocument"
              @cancel="cancelEditDocument"
            >
              <a-form
                class="custom-compact-form"
                :form="form"
                :wrapper-col="{ span: 24 }"
                @submit="handleSubmitDocument"
              >
                <a-form-item>
                  <div>支持png/jpg格式照片，10M以内, 最多8张
                  </div>
                  <upload-image
                    accept=".jpg,.jpeg,.png"
                    :max-count="8"
                    :multiple="true"
                    :default-file-list="defaultDocumentFileList"
                    @change="handleUploadDocument"
                    v-decorator="['documentAttachments',{
                      rules:[
                        {validator: checkDocumentAttachments}
                      ]
                    }]"
                  />
                </a-form-item>
              </a-form>
            </a-modal>
          </a-descriptions-item>
        </a-descriptions>
        <a-descriptions
          :column="1"
          class="custom-descriptions"
          v-if="basicInfo.documents && defaultDocuments.length > 0"
        >
          <div>
            <div class="custom-flex custom-flex-wrap">
              <div v-for="(img, index) in basicInfo.documents" :key="index">
                <img :src="img.url" @click="showImg(img.url)" width="50px" height="50px" style="margin: 5px">
              </div>
            </div>
          </div>
        </a-descriptions>
        <!-- 预览图片 -->
        <preview-image
          :visible.sync="previewImgVisible"
          :image.sync="previewImage"
        />
        <!-- 预览视频 -->
        <preview-video
          :visible.sync="previewVideoVisible"
          :video.sync="previewVideo"
        />

        <edit-life-info
          :visible.sync="isShowEditLifeInfo"
          :id="showingId"
          :life-info="basicInfo.life_info"
          @completed="fetchData"
        />
      </a-col>
      <a-col :span="8">
        <a-descriptions
          :column="1"
          class="custom-descriptions custom-descriptions-max-10"
        >
          <a-descriptions-item label="是否行三跪九叩首大礼">{{ basicInfo.san_gui_jiu_kou_text }}</a-descriptions-item>
          <a-descriptions-item label="家属取抽身布">{{ basicInfo.pull_out_cloth_text }}</a-descriptions-item>
          <a-descriptions-item label="逝者父亲是否健在">{{ basicInfo.father_living }}</a-descriptions-item>
          <a-descriptions-item label="逝者母亲是否健在">{{ basicInfo.mother_living }}</a-descriptions-item>
          <a-descriptions-item label="逝者配偶是否健在">{{ basicInfo.partner_status }}</a-descriptions-item>
          <a-descriptions-item label="逝者子女情况">{{ basicInfo.children_info }}</a-descriptions-item>
        </a-descriptions>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import PreviewImage from '@/components/PreviewImage'
import PreviewVideo from '@/components/PreviewVideo'
import introduction from '@/views/service_order_deaths/tenant/show/Introduction'
import { findServiceOrderDeathBasicInfo } from '@/api/order'
import UploadImage from '@/components/Upload/Image'
import UploadVideo from '@/components/Upload/Video'
import EditDeathInfo from '@/views/orders/tenant/deaths/EditDeathInfo'
import {
  findDeathEditForm, updateServiceOrderDeathDocument,
  updateServiceOrderDeathImg,
  updateServiceOrderDeathVideo
} from '@/api/service_order_death'
export default {
  name: 'BasicInfo',
  components: {
    PreviewImage,
    PreviewVideo,
    introduction,
    UploadImage,
    UploadVideo,
    EditLifeInfo: () => import('@/views/orders/tenant/deaths/EditLifeInfo')
  },
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'BasicInfo' }),
      previewImgVisible: false,
      previewImage: '',
      previewVideoVisible: false,
      previewVideo: '',
      isShowIntroduction: false,
      isShowEditLifeInfo: false,
      isShowImgEdit: false,
      isShowVideoEdit: false,
      defaultFileList: [],
      defaultVideoFileList: [],
      editVideoNum: 0,
      showingId: 0,
      basicInfo: {},
      defaultImages: [],
      defaultVideos: [],
      defaultDocumentFileList: [],
      defaultDocuments: [],
      serviceOrderDeathId: 0,
      deathEditData: {},
      isShowDocumentEdit: false
    }
  },
  filters: {
    operatorNameFilter(value) {
      if (value && value.length > 10) {
        return value.substring(0, 10) + '...'
      }
      return value
    },
    basicInfoNameFilter(value) {
      if (value && value.length > 20) {
        return value.substring(0, 20) + '...'
      }
      return value
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      findServiceOrderDeathBasicInfo(this.id).then(res => {
        if (res.code === 0) {
          this.basicInfo = res.data
          this.defaultImages = res.data.images
          this.defaultVideos = res.data.videos
          this.defaultDocuments = res.data.documents
          this.serviceOrderDeathId = this.basicInfo.id
          this.fetchDeathEditData()
        }
        this.loading = false
      })
    },
    fetchDeathEditData() {
      findDeathEditForm(this.serviceOrderDeathId).then((res) => {
        if (res.code === 0) {
          this.deathEditData = res.data
        }
      })
    },
    showImg(url) {
      this.previewImgVisible = true
      this.previewImage = url
    },
    showVideo(url) {
      this.previewVideoVisible = true
      this.previewVideo = url
    },
    initImgData() {
      this.defaultFileList = []
      if (this.basicInfo.images && this.basicInfo.images.length > 0) {
        this.basicInfo.images.forEach((item, index) => {
          this.defaultFileList.push({
            uid: index,
            name: 'image.png',
            status: 'done',
            url: item.url,
            response: item.response
          })
        })
      }
      this.$nextTick(() => {
        this.attachments = this.defaultFileList
      })
    },
    initVideoData() {
      this.defaultVideoFileList = []
      if (this.basicInfo.videos && this.basicInfo.videos.length !== 0) {
        this.basicInfo.videos.forEach((item, index) => {
          this.defaultVideoFileList.push({
            uid: index,
            name: item.response.filename,
            status: 'done',
            url: '/temp/logo.png',
            response: item.response
          })
        })
      }
      this.$nextTick(() => {
        this.videoAttachments = this.defaultVideoFileList
      })
    },
    initDocumentData() {
      this.defaultDocumentFileList = []
      if (this.basicInfo.documents && this.basicInfo.documents.length !== 0) {
        this.basicInfo.documents.forEach((item, index) => {
          this.defaultDocumentFileList.push({
            uid: index,
            name: 'image.png',
            status: 'done',
            url: item.url,
            response: item.response
          })
        })
      }
      this.$nextTick(() => {
        this.documentAttachments = this.defaultDocumentFileList
      })
    },
    showIntroduction() {
      this.isShowIntroduction = true
    },
    ShowImgEdit() {
      this.isShowImgEdit = true
      this.initImgData()
    },
    ShowVideoEdit() {
      this.isShowVideoEdit = true
      this.initVideoData()
    },
    ShowDocumentEdit() {
      this.isShowDocumentEdit = true
      this.initDocumentData()
    },
    showEditLifeInfo(id) {
      this.showingId = id
      this.isShowEditLifeInfo = true
    },
    showEditDeathInfo() {
      this.isShowEditDeathInfo = true
    },
    handleUploadImage(fileList) {
      this.attachments = fileList
    },
    handleUploadVideo(fileList) {
      this.videoAttachments = fileList
    },
    handleUploadDocument(fileList) {
      this.documentAttachments = fileList
    },
    // 检查照片
    checkAttachments(rule, value, callback) {
      for (const key in this.attachments) {
        if (this.attachments[key] && this.attachments[key].status === 'error') {
          // eslint-disable-next-line standard/no-callback-literal
          callback('照片上传错误')
          return
        }
      }

      callback()
    },
    // 检查视频
    checkVideoAttachments(rule, value, callback) {
      for (const key in this.videoAttachments) {
        if (this.videoAttachments[key] && this.videoAttachments[key].status === 'error') {
          // eslint-disable-next-line standard/no-callback-literal
          callback('视频上传错误')
          return
        }
      }

      callback()
    },
    // 检查证件
    checkDocumentAttachments(rule, value, callback) {
      for (const key in this.documentAttachments) {
        if (this.documentAttachments[key] && this.documentAttachments[key].status === 'error') {
          // eslint-disable-next-line standard/no-callback-literal
          callback('证件上传错误')
          return
        }
      }

      callback()
    },
    handleSubmitImg(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      if (this.$lodash.find(this.attachments, { status: 'uploading' })) {
        this.$warning({
          title: '文件尚未上传完成',
          content: ''
        })
        return
      }

      this.form.validateFields((err) => {
        if (!err) {
          this.submitting = true
          const editImg = {
            attachments: this.$lodash.map(this.attachments, 'response')
          }
          updateServiceOrderDeathImg(this.basicInfo.id, editImg).then(res => {
            if (res.code === 0) {
              this.isShowImgEdit = false
              this.$emit('completed')
              this.fetchData()
            }
            this.submitting = false
          })
        }
      })
    },
    handleSubmitVideo(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      if (this.$lodash.find(this.videoAttachments, { status: 'uploading' })) {
        this.$warning({
          title: '文件尚未上传完成',
          content: ''
        })
        return
      }
      this.form.validateFields((err) => {
        if (!err) {
          this.submitting = true
          const editVideo = {
            videoAttachments: this.$lodash.map(this.videoAttachments, 'response')
          }
          updateServiceOrderDeathVideo(this.basicInfo.id, editVideo).then(res => {
            if (res.code === 0) {
              this.isShowVideoEdit = false
              this.$emit('completed')
              this.fetchData()
            }
            this.submitting = false
          })
        }
      })
    },
    handleSubmitDocument(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      if (this.$lodash.find(this.documentAttachments, { status: 'uploading' })) {
        this.$warning({
          title: '文件尚未上传完成',
          content: ''
        })
        return
      }
      this.form.validateFields((err) => {
        if (!err) {
          this.submitting = true
          const editDocument = {
            documentAttachments: this.$lodash.map(this.documentAttachments, 'response')
          }
          updateServiceOrderDeathDocument(this.basicInfo.id, editDocument).then(res => {
            if (res.code === 0) {
              this.isShowDocumentEdit = false
              this.$emit('completed')
              this.fetchData()
            }
            this.submitting = false
          })
        }
      })
    },
    cancelEditImg() {
      this.submitting = false
      this.isShowImgEdit = false
    },
    cancelEditVideo() {
      this.submitting = false
      this.isShowVideoEdit = false
    },
    cancelEditDocument() {
      this.submitting = false
      this.isShowDocumentEdit = false
    },
    cancelShowIntroduction() {
      this.isShowIntroduction = false
    }
  }
}
</script>
<style lang= "less" scoped>
.edit-class {
  margin: 10px;
}

.video {
  border: 1px solid #a7b6be36;
  padding: 5px;
  margin: 5px;
}
</style>
